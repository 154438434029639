import link from 'common/assets/svg/jobs/link.svg';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import invoices from 'common/assets/svg/common/invoices.svg';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import GoodForPaymentLabel from 'views/accounting/invoices/components/GoodForPaymentLabel';
import SendToAccountingLabel from 'views/accounting/invoices/components/SendToAccountingLabel';
import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import permissions from 'common/utils/permissions/constants';
import { isDepartmentAccessDisabledByPermission } from 'views/accounting/invoices/helpers';
import { useSelector } from 'react-redux';
import { selectAccount } from 'store/account/selectors';
import { useMemo } from 'react';
import usePrevious from '@/ts-common/utils/hooks/usePrevious';

export const ItemButton = ({ btn, data, index, viewOnly, drawer }) => {
  const { tooltipID, avoidRender, resetTooltip } = useTooltipID(`invoice-tooltip_${index}`);
  const prevTooltipID = usePrevious(tooltipID); // Fixes re-rendering bug with tooltipID

  const onClick = async () => {
    if (viewOnly) return;
    else {
      await resetTooltip();
      btn.onClick();
    }
  };

  return (
    <>
      <Button
        className={`text-white cme-4 ${btn.className} ${viewOnly ? 'pointer-events-none' : ''}`}
        type="button"
        color={btn.color}
        onClick={onClick}
        size={'xs'}
        disabled={btn.disabled}
        id={tooltipID}
      >
        <div className="d-flex align-items-center text-primary">
          <SvgRender src={btn?.icon} style={btn?.iconStyle} />
          {btn?.count ? (
            <div className="fs-12 text-nowrap cms-4 fw-medium">{data?.invoice_links_count}</div>
          ) : null}
        </div>
      </Button>
      {btn.tooltipMessage &&
      !avoidRender &&
      tooltipID &&
      !drawer.isOpen &&
      (prevTooltipID === tooltipID || !prevTooltipID) ? (
        <Tooltip target={tooltipID} placement={btn.tooltipPlacement} fade={false}>
          {btn?.tooltipMessage}
        </Tooltip>
      ) : null}
    </>
  );
};

const InvoiceActionButtons = ({
  drawer,
  data,
  setActiveID,
  setShowLinkCases,
  refetchData,
  viewOnly = false,
  departments
}) => {
  const account = useSelector(selectAccount);

  const actionButtons = useMemo(
    () => [
      {
        label: 'Linked Case',
        color: 'yellow',
        icon: link,
        iconStyle: { width: 11, height: 11 },
        className: `text-primary d-flex justify-content-center min-width-38`,
        onClick: () => {
          setActiveID(data.id);
          drawer.open();
          setShowLinkCases(true);
        },
        count: true,
        tooltipMessage: 'Linked Case',
        disabled: isDepartmentAccessDisabledByPermission(
          { departments: departments },
          permissions.office.accounting.invoices.link_with_cases,
          account
        )
      },
      {
        label: 'Invoice',
        color: 'primary',
        icon: invoices,
        iconStyle: { width: 11, height: 11, color: 'white' },
        onClick: () => {
          setActiveID(data.id);
          drawer.open();
        },
        tooltipMessage: 'Invoice',
        disabled: isDepartmentAccessDisabledByPermission(
          { departments: departments },
          permissions.office.accounting.invoices.view,
          account
        )
      },
      {
        label: 'Good for Payment',
        component: GoodForPaymentLabel
      },
      {
        label: 'Send to Accounting',
        component: SendToAccountingLabel
      }
    ],
    [account, data.id, departments, drawer, setActiveID, setShowLinkCases]
  );

  return (
    <div className="d-flex">
      {actionButtons.map((btn, i) => {
        const Button = btn.component || ItemButton;

        return btn.count && !data?.invoice_links_count ? null : (
          <Button
            btn={btn}
            data={data}
            key={i}
            index={i}
            refetchData={refetchData}
            viewOnly={viewOnly}
            setActiveID={setActiveID}
            drawer={drawer}
          />
        );
      })}
    </div>
  );
};

InvoiceActionButtons.propTypes = {
  data: PropTypes.shape({
    bunkers: PropTypes.shape({
      id: PropTypes.number,
      invoice_links_count: PropTypes.number
    })
  }),
  drawer: DrawerState,
  setActiveID: PropTypes.func,
  setShowLinkCases: PropTypes.func,
  refetchData: PropTypes.func,
  viewOnly: PropTypes.bool
};

export default InvoiceActionButtons;
