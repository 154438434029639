import { getRequisitionSummary } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  activeRequisitionSuppliers: []
};

const AMOUNT_OF_SUPPLIERS_SHOWN = 2;

const slice = createSlice({
  name: 'requisitionSummary',
  initialState: INITIAL_STATE,
  reducers: {
    setSupplierIsActive: (state, { payload }) => {
      if (state.activeRequisitionSuppliers.includes(payload)) {
        state.activeRequisitionSuppliers = state.activeRequisitionSuppliers.filter(
          supplier => supplier !== payload
        );
        return state;
      }

      if (state.activeRequisitionSuppliers.length === AMOUNT_OF_SUPPLIERS_SHOWN) {
        const suppliers = state.activeRequisitionSuppliers.map((supplier, index) => {
          if (index + 1 === AMOUNT_OF_SUPPLIERS_SHOWN) return payload;

          return supplier;
        });

        state.activeRequisitionSuppliers = suppliers;
        return state;
      }

      state.activeRequisitionSuppliers.push(payload);

      return state;
    }
  },
  extraReducers: {
    [getRequisitionSummary.pending]: () => {
      return {};
    },
    [getRequisitionSummary.fulfilled]: (state, { payload }) => {
      state = {
        ...payload,
        activeRequisitionSuppliers: []
      };
      return state;
    },
    [getRequisitionSummary.rejected]: () => {
      return {};
    },
    [LOCATION_CHANGE]: () => {
      return {};
    }
  }
});
export const { setSupplierIsActive } = slice.actions;

export default slice.reducer;
