import TYPES from './types';

const INITIAL_STATE = {};

const _stateHistory = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.UPDATE_STATE_HISTORY: {
      return {
        ...state,
        [payload.key]: payload.data
      };
    }
    default:
      return state;
  }
};

export default _stateHistory;
