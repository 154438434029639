import { useCallback, useMemo } from 'react';
import CollapseForm from 'common/components/collapse/CollapseForm';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import arrowFilter from 'common/assets/svg/common/arrows/arrow-filters.svg';
import comments from 'common/assets/svg/common/comments.svg';
import { PreviewHtml } from 'common/components/form/inputs/Editor';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import {
  selectRequisitionSuppliers,
  selectActiveRequisitionSupplierIds,
  getSupplierTooltipLabel,
  getSupplierPrice,
  selectRequisitionItems
} from 'store/purchasing/requisition-summary/selectors';
import {
  selectSupplierHistoryByStatus,
  selectSupplierDeliveryRemarks,
  selectSupplierRemarks,
  selectSupplierIsRejected,
  selectSupplier
} from 'common/components/purchasing/requisition/store/selectors';
import { numberToStr } from 'common/utils/numbers';
import { dateToLocal } from 'common/utils/dates';
import { useDispatch } from 'react-redux';
import { setSupplierIsActive } from 'store/purchasing/requisition-summary/slice';
import Information from 'common/components/general/Information';
import infoIcon from 'common/assets/svg/common/info-line.svg';
import { selectListDefaultOptions } from 'store/lists/selectors';

const Supplier = ({ data }) => {
  const dispatch = useDispatch();
  const activeRequisitionSupplierIds = useSelector(selectActiveRequisitionSupplierIds);
  const items = useSelector(selectRequisitionItems);
  const requisitionStatuses = useSelector(state =>
    selectListDefaultOptions(state, 'purchasing-requisition-statuses')
  );

  const tooltipLabel = useSelector(state => getSupplierTooltipLabel(state, data.id));
  const localPrice = useSelector(state => getSupplierPrice(state, data.id));
  const equivalentPrice = useSelector(state => getSupplierPrice(state, data.id, true));

  const poHistory = useSelector(state => selectSupplierHistoryByStatus(state, data.id, 'po'));
  const deliveryRemarks = useSelector(state => selectSupplierDeliveryRemarks(state, data.id));
  const remarks = useSelector(state => selectSupplierRemarks(state, data.id));

  const supplierIsRejected = useSelector(state => selectSupplierIsRejected(state, data?.id));
  const supplier = useSelector(state => selectSupplier(state, data.id));

  const poStatusIndex = useMemo(
    () => requisitionStatuses.find(status => status.label === 'po')?.sort_index,
    [requisitionStatuses]
  );
  const purchaseOrderIsProceeded = data?.status?.sort_index >= poStatusIndex;

  const isRfq = data?.status?.label === 'rfq';
  const isActive = useMemo(
    () => activeRequisitionSupplierIds.includes(data.id),
    [activeRequisitionSupplierIds, data.id]
  );

  const onClick = useCallback(() => dispatch(setSupplierIsActive(data.id)), [dispatch, data.id]);

  const supplierHasItemsWithProposedQuantity = items
    .map(item => item.supplier_details.find(s => s.requisition_supplier_id === supplier?.id))
    .some(supplierItem => supplierItem?.proposed_quantity);

  return (
    <div className="requisition-supplier cmt-2">
      <CollapseForm
        className={`requisition-supplier__form requisition-supplier__form--summary ${
          purchaseOrderIsProceeded && supplierHasItemsWithProposedQuantity ? 'proceeded' : ''
        }`}
        headerClassName="w-100p"
        triggerOpen={isActive}
        header={
          <Row className="text-primary fs-12 pointer" onClick={onClick} noGutters>
            <Col xs={3} className="ps-4">
              <TextWithTooltip>{data.supplier?.full_name || ''}</TextWithTooltip>
            </Col>
            <Col xs="auto" className="min-w-80 max-w-80">
              {isRfq ? (
                <span className="fw-medium fs-10 text-violet">No QTN</span>
              ) : supplierIsRejected ? (
                <span className="fw-medium fs-10 text-red">Rejected</span>
              ) : poHistory ? (
                dateToLocal(poHistory?.created_at).format('DD/MM/YYYY')
              ) : (
                ''
              )}
            </Col>
            <Col className="d-flex flex-nowrap justify-content-between align-items-center pe-2">
              {tooltipLabel ? (
                <Information
                  message={`${tooltipLabel} Amount`}
                  svgIcon={infoIcon}
                  className={'text-violet'}
                  svgStyle={{
                    width: 12,
                    height: 12
                  }}
                />
              ) : null}
              {data.quotation_currency?.label && equivalentPrice !== null
                ? `${data.quotation_currency?.label} `
                : ''}
              {numberToStr(localPrice, 2, 2)}
            </Col>
            <Col className="text-end pe-2">{numberToStr(equivalentPrice, 2, 2)}</Col>
            <Col xs={3}>
              <TextWithTooltip>{deliveryRemarks}</TextWithTooltip>
            </Col>
          </Row>
        }
        customExpandComponent={() =>
          isRfq ? (
            <div />
          ) : (
            <Button
              color="link"
              className="p-0 position-absolute border border-primary rounded-circle text-primary d-inline-flex align-items-center justify-content-center requisition-supplier__form--summary-toggle"
              onClick={onClick}
            >
              <SvgRender
                src={arrowFilter}
                style={{ width: 7, height: 7, transform: isActive ? `` : 'rotate(180deg)' }}
              />
            </Button>
          )
        }
        hideExpandButton={true}
        hideActions={true}
        disableCollapse={true}
      >
        <div className="text-primary fs-12 pt-1 ps-1">
          <div className="d-flex align-items-center text-violet">
            <SvgRender src={comments} style={{ width: 12, height: 12 }} />
            <span className="ps-1 fs-10 fw-medium lh-1">Remarks</span>
          </div>
          <div className={`${remarks ? 'cmt-6' : ''}`}>
            <PreviewHtml value={remarks} />
          </div>
        </div>
      </CollapseForm>
    </div>
  );
};

const Suppliers = () => {
  const suppliers = useSelector(selectRequisitionSuppliers);

  return (
    <>
      <Row className="text-primary fs-10 fw-bold pt-1" noGutters>
        <Col xs={3} className="ps-4">
          SUPPLIER
        </Col>
        <Col xs="auto" className="min-w-80 max-w-80">
          PO DATE
        </Col>
        <Col>LOCAL TOTAL</Col>
        <Col xs={2}>USD</Col>
        <Col xs={3}>DELIVERY REMARKS</Col>
      </Row>
      <div className="pb-6">
        {suppliers?.map(supplier => (
          <Supplier key={supplier.id} data={supplier} />
        ))}
      </div>
    </>
  );
};

export default Suppliers;
