import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import checkIcon from 'assets/svg/actions/check.svg';
import reviewProcessIcon from 'common/assets/svg/common/review-process.svg';
import PropTypes from 'prop-types';
import { setReviewProcessDrawerIsOpen } from 'common/components/review-process/store/actions';
import { useDispatch } from 'react-redux';
import { DrawerState } from '@/common/entities/drawer/DrawerTypes';
import usePrevious from '@/ts-common/utils/hooks/usePrevious';

const GoodForPaymentLabel = ({ data, setActiveID, drawer }) => {
  const { avoidRender, tooltipID, resetTooltip } = useTooltipID(`reviewed-label`);
  const prevTooltipID = usePrevious(tooltipID); // Fixes re-rendering bug with tooltipID

  const dispatch = useDispatch();

  const hasReviewProcess = data.is_completed === true || data.is_completed === false; // is_completed is null when there is no review process or when it has not been triggered
  const isGoodForPayment = data.is_completed === true || data.reviewed_by_id; // is_completed is used for invoices with review process, reviewed_by_id is used for invoices without a review process

  return (
    <div
      className={`${
        isGoodForPayment ? 'bg-turquoise' : 'bg-white'
      }  border border-turquoise d-flex align-items-center justify-content-center me-0 border-radius-3 cme-4 width-20 ${
        hasReviewProcess ? 'cursor-pointer' : ''
      }`}
      id={tooltipID}
      data-cy="is-good-for-payment-label"
      onClick={async () => {
        await resetTooltip();
        if (hasReviewProcess) {
          setActiveID(data.id);
          drawer.open();
          dispatch(setReviewProcessDrawerIsOpen(true, data.id));
        }
      }}
    >
      <SvgRender
        src={hasReviewProcess ? reviewProcessIcon : checkIcon}
        style={{
          width: 11,
          height: 11,
          color: isGoodForPayment ? 'white' : '#01d9a4'
        }}
      />
      {tooltipID &&
      !avoidRender &&
      !drawer.isOpen &&
      (prevTooltipID === tooltipID || !prevTooltipID) ? (
        <Tooltip target={tooltipID} fade={false}>
          {isGoodForPayment ? (
            'Good for Payment'
          ) : hasReviewProcess ? (
            <div className="text-start">
              <div className="cpb-6 border-bottom border-davys-gray cmb-6">Not Reviewed</div>
              <div>
                Invoice will be “Good for Payment” when all mandatory approval steps are completed.
              </div>
            </div>
          ) : (
            'Not Reviewed'
          )}
        </Tooltip>
      ) : null}
    </div>
  );
};

GoodForPaymentLabel.propTypes = {
  drawer: DrawerState,
  setActiveID: PropTypes.func,
  data: PropTypes.shape({
    is_completed: PropTypes.bool,
    reviewed_by_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};

export default GoodForPaymentLabel;
